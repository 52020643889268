import React, { createContext, useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { majorScale, Pane, Paragraph } from 'evergreen-ui';
import Btn from '../../components/btn/btn';
import { isAuthVar, authUserVar } from '../../helpers/auth';
import loadGoogleAPI from '../../libs/loadGoogleAPI';
import loadGoogleRecaptchaAPI from '../../libs/loadGoogleRecaptchaAPI';
import clientStorage from '../../libs/client-storage';
import { TS_ACCEPT_COOKIES } from '../../constants/index';
import loadCloudianryWidget from '../../libs/loadCloudinaryWidget';
import favicon from '../../assets/icons/favicon-cokobar.ico';
import favicon48 from '../../assets/icons/favicon-cokobar-48.png';
import favicon98 from '../../assets/icons/favicon-cokobar-98.png';
import favicon144 from '../../assets/icons/favicon-cokobar-144.png';
import faviconSvg from '../../assets/icons/favicon-cokobar-144.svg';
import favicon180 from '../../assets/icons/favicon-cokobar-180.png';
import { useAppContext } from '../../providers/cookie-policy';
import loadQueueClient from '../../libs/loaderqueueitclient';
import loaderqueueconfigloader from '../../libs/loaderqueueitconfigloader';
import {
  SuperAffiliateContext,
  superAffiliateObject,
} from '../../providers/super-affiliate';
import AppleSigninPersonalInfo from '../../components/apple-signin-personal-info/apple-signin-personal-info';
import GuestCheckoutDialog from '../../components/guest-checkout-dialog/guest-checkout-dialog';

const StyledLink = styled(Link)`
  color: var(--secondary-light-color);

  &:hover {
    color: var(--secondary-default-color);
  }
`;

const check = (isGuestLogin) => {
  if (isGuestLogin.loginFlow && isGuestLogin.status === 'AWAITINGACTIVATION') {
    return true;
  }
  if (isGuestLogin.signupFlow && isGuestLogin.status === 'AWAITINGACTIVATION') {
    return true;
  }
  if (isGuestLogin.signupFlow && isGuestLogin.isEmailExists) {
    return true;
  }
  return false;
};

const RenderAppSubContents = () => {
  const { isCookiesAccepted, isGuestLogin } = useAppContext();
  const authUser = useReactiveVar(authUserVar);
  const isAuth = useReactiveVar(isAuthVar);

  useEffect(() => {
    // Load necessary APIs and resources
    loadCloudianryWidget();
    loadGoogleAPI();
    loadGoogleRecaptchaAPI();
    loadQueueClient();
    loaderqueueconfigloader();
  }, []);

  return (
    <>
      <Helmet>
        <title>{superAffiliateObject.businessName}</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        {/* Standard Favicons  */}
        {/* <link rel="icon" type="image/x-icon" href={favicon} />
        <link rel="icon" type="image/png" sizes="48x48" href={favicon48} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon98} />
        <link rel="icon" type="image/png" sizes="144x144" href={favicon144} /> */}

        {/* Apple Touch Icons */}
        {/* <link rel="apple-touch-icon" sizes="180x180" href={favicon180} /> */}

        {/* SVG Favicon */}
        {/* <link rel="icon" type="image/svg+xml" href={faviconSvg} /> */}
      </Helmet>
      {!isCookiesAccepted && <AcceptCookiesMessage />}

      {authUser && isAuth && authUser?.appleAuthUser && (
        <AppleSigninPersonalInfo />
      )}
      {check(isGuestLogin) && <GuestCheckoutDialog />}
    </>
  );
};

export default RenderAppSubContents;

export const AppWrapper = ({ children }) => (
  <>
    <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
    </Helmet>
    {children}
  </>
);

const AcceptCookiesMessage = () => {
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const { handleAcceptCookies } = useAppContext();

  return (
    <Pane
      fontFamily="Proxima Nova, sans-serif"
      width="100%"
      position="fixed"
      left="0"
      bottom="0"
      is="footer"
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize="0.9rem"
      backgroundColor="var(--primary-default-color)"
      color="white"
      zIndex="100"
      padding={majorScale(1)}
    >
      <Pane
        maxWidth={1200}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={majorScale(2)}
      >
        <Paragraph color="white">
          Thank you for visiting {superAffiliate.businessName}. By using the
          site, you agree to our{' '}
          <StyledLink to="/privacy-policy"> privacy policy </StyledLink> and our
          use of cookies.
        </Paragraph>
        <Btn look="secondary-filled" onClick={handleAcceptCookies}>
          Got it
        </Btn>
      </Pane>
    </Pane>
  );
};
