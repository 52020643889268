import { gql } from '@apollo/client';

export const GET_EVENT_BASICS_QUERY = gql`
  query GetEventBasicsQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        EventId: eventId
        CompanyId: companyId
        EventTitle: eventTitle
        EventDescription: eventDescription
        coverImageUrl
        coverImageThumbnailUrl

        EventMedia: eventMedia {
          mediaUrl: mediaUrl
        }

        EventGenres: eventGenres {
          EventGenreType: eventGenreType {
            EventGenreTypeId: eventGenreTypeId
            EventGenreTypeDescription: eventGenreTypeDescription
            EventGenreTypeShortcode: eventGenreTypeShortcode
          }
        }

        OptionAge: optionAge
        OptionLastEntry: optionLastEntry
        OptionDressCode: optionDressCode
        OptionIdRequirement: optionIdRequirement
        HatAccepted: hatAccepted
        ShoeType: shoeType
        optionAgeDescription
        optionIdRequirementDescription
        optionDressCodeDescription
        shoeTypeDescription
        hatAcceptedDescription
        eventDressCodeTerms {
          eventId
          fieldTitle
          fieldDesctiption
          id
        }

        EventTypes: eventTypeNavigation {
          EventTypeShortcode: eventTypeShortcode
          EventTypeDescription: eventTypeDescription
          EventTypeId: eventTypeId
        }

        IsPrivate: isPrivate
      }
    }
  }
`;

export const GET_EVENT_LOCATION_QUERY = gql`
  query GetEventLocationQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        EventId: eventId
        isHiddenVenue
        Status: status
        Venue: venue {
          PlaceId: placeId
          venueDescription
          VenueTitle: venueTitle
          AddressLine1: addressLine1
          AddressLine2: addressLine2
          City: city
          PostCode: postCode
          longitude: longitude
          latitude: latitude
          Country: country {
            ISO2: iso2
            CountryId: countryId
            timezone
          }
        }
        virtualLink
        virtualLinkExtraInformation
        Products: products {
          ProductId: productId
          transactionItemsAggregate
        }
      }
    }
  }
`;

// virtualLink;
// hostEventName;

// todo: missing fields
// fixedFee refundPolicyNumOfDays, exemptions, numOfTimesToBeScheduled, Products(hideTicket, quantityAvailable, numOfTicketsSold, ticketType)

export const GET_EVENT_TICKETS_QUERY = gql`
  query GetEventTicketsQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        EventId: eventId
        EventTitle: eventTitle
        eventCapacity: eventCapacity
        venueCapacity: venueCapacity
        CurrencyId: currencyId
        SchedulePeriod: schedulePeriod
        enableWaitlist: enableWaitlist
        waitlistEndDate: _waitlistEndDate
        waitlistStartDate: _waitlistStartDate
        _waitlistEndDate: waitlistEndDate
        _waitlistStartDate: waitlistStartDate
        isRefundable: isRefundable
        eventBriteEventId
        ScheduledFrequencyId: scheduleFrequencyId
        ScheduledFrequency: scheduledFrequency {
          ScheduleFrequencyId: scheduleFrequencyId
        }

        TicketAdminPercentage: ticketAdminPercentage
        TicketAdminBase: ticketAdminBase
        Status: status
        TicketSaleStart: ticketSaleStart
        TicketSaleClose: ticketSaleClose
        Venue: venue {
          CountryId: countryId
          Country: country {
            ISO2: iso2
            CurrencyId: currencyId
            timezone
          }
        }

        CurrencyDetail: currency {
          CurrencyShortcode: currencyShortcode
          CurrencyId: currencyId
          CurrencySymbol: currencySymbol
        }

        EventDates: eventDates {
          EventEndDate: _eventEndDate
          EventStartDate: _eventStartDate
          lastEntryTime: _lastEntryTime
          _EventEndDate: eventEndDate
          _EventStartDate: eventStartDate
          _lastEntryTime: lastEntryTime
          EventDateId: eventDateId
          eventDateName: eventDateName
          startSaleDate: _startSaleDate
          endSaleDate: _endSaleDate
          _startSaleDate: startSaleDate
          _endSaleDate: endSaleDate
          preSaleEnabled: preSaleEnabled
          preSaleEndDate: _preSaleEndDate
          preSaleStartDate: _preSaleStartDate
          _preSaleEndDate: preSaleEndDate
          _preSaleStartDate: preSaleStartDate
          preSaleSendEmailTime: preSaleSendEmailTime
          preSaleUseUniqueCode: preSaleUseUniqueCode
        }

        Products: products(order: { productPrice: ASC, isSoldOut: ASC }) {
          ProductId: productId
          EventDateId: eventDateId
          ProductReferenceNumber: productReferenceNumber
          ProductTitle: productTitle
          ProductPrice: productPrice
          Quantity: quantity
          AdminFee: adminFee
          ProductDescription: productDescription
          IsAgentSellable: isAgentSellable
          MinimumInterestQuantity: minimumInterestQuantity
          MaximumInterestQuantity: maximumInterestQuantity
          eventBriteProductId
          isEventBriteProduct
          StartSaleDate: _startSaleDate
          EndSaleDate: _endSaleDate
          _StartSaleDate: startSaleDate
          _EndSaleDate: endSaleDate
          PreSaleEnabled: preSaleEnabled
          PreSaleEndDate: preSaleEnabled
          PreSaleStartDate: _preSaleStartDate
          _PreSaleStartDate: preSaleStartDate
          PresaleQuantity: presaleQuantity
          PresaleSold: presaleSold
          IsAbsorbCommission: isAbsorbCommission
          IsSoldOut: isSoldOut
          IsExcludeFromWebsite: isExcludeFromWebsite
          LockedTicket: lockedTicket
          isSeating
          TransactionItems: transactionItems(
            where: {
              isActive: { eq: true }
              transaction: { transactionStatusId: { in: [2, 10] } }
            }
          ) {
            Quantity: quantity
          }
          transactionItems_aggregate_TransactionStatus_Quantity
          transactionItems_aggregate_TransactionStatus_TotalItemPrice
        }
        totalRevenue: totalRevenue
      }
    }
  }
`;

export const GET_EVENT_HOST_QUERY = gql`
  query GetEventHostQuery($eventId: uuid!) {
    event_by_pk(id: $eventId) {
      id
      Host {
        id
        name
        email
      }
    }
  }
`;
// export const GET_HOST_BANK_DETAILS_QUERY = gql`
//   query EventBankDetailsQuery($id: uuid!) {
//     event_by_pk(id: $id) {
//       bank {
//         accountName
//         accountNumber
//         accountType
//         bankName
//         country {
//           id
//         }
//         city
//         address
//         postCode
//         routingNumber
//         userType
//       }
//     }
//   }
// `;

export const GET_HOST_BANK_DETAILS_QUERY = gql`
  query GetHostBankDetailsQuery($hostId: uuid!) {
    bank(where: { hostId: { _eq: $hostId } }) {
      userType
      accountName
      address
      city
      postCode
      countryId
      accountType
      bankName
      routingNumber
      accountNumber
    }
  }
`;

// export const GET_EVENT_DISCOUNTS_QUERY = gql`
//   query GetEventDiscountsQuery($eventId: uuid!) {
//     event_by_pk(id: $eventId) {
//       id
//       discounts {
//         id
//         code
//         discount
//         quantity
//         ticketUsageCount
//         applicableTicketCategory
//         expiryDate
//       }
//     }
//   }
// `;

export const GET_EVENT_DISCOUNTS_QUERY = gql`
  query EventDiscountCodesQuery($eventId: uuid!) {
    event_by_pk(id: $eventId) {
      discounts {
        id
        code
        discount
        expiryDate
        quantity
        ticketUsageCount
        applicableTicketCategory
      }
      tickets {
        id
        name
      }
      status
    }
  }
`;

export const GET_EVENT_TICKET_BUYER_CURRENCY_QUERY = gql`
  query GetEventTicketBuyerCurrencyQuery($eventId: uuid!) {
    event_by_pk(id: $eventId) {
      id
      buyerPaysInCurrency {
        id
        shortcode
      }
    }
  }
`;

export const GET_EVENT_COUNTRY = gql`
  query GetEventCountryQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        Venue: venue {
          Country: country {
            ISO2: iso2
            CurrencyId: currencyId
            timezone
          }
        }
      }
    }
  }
`;

export const GET_EVENTS_QUERY = gql`
  query GetEventQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        AgentPromotionDefaultCommissionPercentage: agentPromotionDefaultCommissionPercentage
        PromoterApprovalType: promoterApprovalType
        EventId: eventId
        CompanyId: companyId
        EventTitle: eventTitle
        EventDescription: eventDescription
        coverImageUrl
        coverImageThumbnailUrl
        EventMedia: eventMedia {
          mediaUrl
        }
        EventGenres: eventGenres {
          EventGenreType: eventGenreType {
            EventGenreTypeId: eventGenreTypeId
            EventGenreTypeDescription: eventGenreTypeDescription
            EventGenreTypeShortcode: eventGenreTypeShortcode
          }
        }

        HatAccepted: hatAccepted
        ShoeType: shoeType
        OptionAge: optionAge
        OptionLastEntry: optionLastEntry
        OptionDressCode: optionDressCode
        OptionIdRequirement: optionIdRequirement
        EventTypes: eventTypeNavigation {
          EventTypeShortcode: eventTypeShortcode
          EventTypeDescription: eventTypeDescription
          EventTypeId: eventTypeId
        }
        IsPrivate: isPrivate
        Status: status

        Venue: venue {
          PlaceId: placeId
          VenueTitle: venueTitle
          AddressLine1: addressLine1
          CountryId: countryId
          AddressLine2: addressLine2
          City: city
          PostCode: postCode
          longitude
          latitude
          Country: country {
            ISO2: iso2
            CountryId: countryId
            CurrencyId: currencyId
            timezone
          }
        }
        virtualLink
        virtualLinkExtraInformation

        eventCapacity
        venueCapacity
        CurrencyId: currencyId
        SchedulePeriod: schedulePeriod
        ScheduleFrequencyId: scheduleFrequencyId
        enableWaitlist: enableWaitlist
        waitlistEndDate
        waitlistStartDate
        TicketAdminPercentage: ticketAdminPercentage
        TicketAdminBase: ticketAdminBase
        Status: status
        TicketSaleStart: ticketSaleStart
        TicketSaleClose: ticketSaleClose

        CurrencyDetail: currency {
          CurrencyShortcode: currencyShortcode
          CurrencyId: currencyId
          CurrencySymbol: currencySymbol
        }

        EventDates: eventDates {
          EventEndDate: _eventEndDate
          EventStartDate: _eventStartDate
          lastEntryTime: _lastEntryTime
          _EventEndDate: eventEndDate
          _EventStartDate: eventStartDate
          _lastEntryTime: lastEntryTime
          EventDateId: eventDateId
          eventDateName: eventDateName
          startSaleDate: _startSaleDate
          endSaleDate: _endSaleDate
          _startSaleDate: startSaleDate
          _endSaleDate: endSaleDate
          preSaleEnabled: preSaleEnabled
          preSaleEndDate: _preSaleEndDate
          preSaleStartDate: _preSaleStartDate
          _preSaleEndDate: preSaleEndDate
          _preSaleStartDate: preSaleStartDate
          preSaleSendEmailTime: preSaleSendEmailTime
          preSaleUseUniqueCode: preSaleUseUniqueCode
        }

        Products: products(order: { productPrice: ASC }) {
          ProductId: productId
          EventDateId: eventDateId
          ProductReferenceNumber: productReferenceNumber
          ProductTitle: productTitle
          ProductPrice: productPrice
          Quantity: quantity
          AdminFee: adminFee
          ProductDescription: productDescription
          IsAgentSellable: isAgentSellable
          MinimumInterestQuantity: minimumInterestQuantity
          MaximumInterestQuantity: maximumInterestQuantity
          StartSaleDate: _startSaleDate
          EndSaleDate: _endSaleDate
          _StartSaleDate: startSaleDate
          _EndSaleDate: endSaleDate
          PreSaleEnabled: preSaleEnabled
          PreSaleEndDate: preSaleEnabled
          PreSaleStartDate: preSaleStartDate
          PresaleQuantity: presaleQuantity
          PresaleSold: presaleSold
          IsAbsorbCommission: isAbsorbCommission
          IsSoldOut: isSoldOut
          IsExcludeFromWebsite: isExcludeFromWebsite
          LockedTicket: lockedTicket
          transactionItemsAggregate
        }

        totalRevenue
      }
    }
  }
`;

export const GET_PROMOTER_EVENTS_COMMISSION = gql`
  query GetEventBasicsQueryPromoter($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        AgentPromotionDefaultCommissionPercentage: agentPromotionDefaultCommissionPercentage
        PromoterApprovalType: promoterApprovalType
        Status: status
      }
    }
  }
`;

export const GET_GENERAL_SETTING_TICKETS_QUERY = gql`
  query GetEventTicketsGeneralSettingQuery($eventId: UUID!) {
    allEvents(where: { eventId: { eq: $eventId } }) {
      items {
        venueCapacity
        eventCapacity
        isRefundable
      }
    }
  }
`;

export const GET_HOSTNAME_QUERY = gql`
  query HostLogoUrlV1($where: CompanyFilterInput!) {
    Company: allCompanies(where: $where) {
      Company: items {
        CompanyName: companyName
        CompanyId: companyId
      }
    }
  }
`;
